var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"app-desktop-navigation"},[_c('ul',{staticClass:"app-desktop-navigation-list"},_vm._l((_vm.links),function(link,index){return _c('li',{key:index,staticClass:"app-desktop-navigation-list-item"},[_c('router-link',{class:[
          'app-desktop-navigation-list-link',
          { 'only-exact': link.to === '/' }
        ],attrs:{"to":link.to}},[(link.icon)?_c('a-icon',{staticClass:"mr-10",staticStyle:{"color":"#b6b6b9"},attrs:{"type":link.icon}}):_vm._e(),_c('span',{staticClass:"app-desktop-navigation-list-link-label mr-5"},[_vm._v(" "+_vm._s(link.label)+" ")]),_c('a-badge',{attrs:{"show-zero":"","count":link.count,"number-style":{
            color: '#535353',
            fontWeight: 600,
            backgroundColor: '#dedede'
          }}})],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }